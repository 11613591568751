import { useState } from "#app";

export type ConfirmDialogPayload = {
  title?: string;
  body?: string;
  confirmButton?: string;
  cancelButton?: string;
  showDontShowAgain?: boolean;
  primaryButtonColour?: string;
};

type DialogType = "confirm" | "delete";

const DEFAULT_CONFIRM_DIALOG_FIELDS: ConfirmDialogPayload = {
  body: "Are you sure you want to perform this action?",
  title: "Confirm Action",
  confirmButton: "Confirm",
  cancelButton: "Cancel",
  showDontShowAgain: false,
};

const DEFAULT_DELETE_DIALOG_FIELDS: ConfirmDialogPayload = {
  body: "Are you sure you want to delete?",
  title: "Confirm Delete",
  confirmButton: "Delete",
  cancelButton: "Cancel",
  showDontShowAgain: false,
  primaryButtonColour: "negative",
};

const getDefault = (type: DialogType) => {
  switch (type) {
    case "confirm":
      return DEFAULT_CONFIRM_DIALOG_FIELDS;
    case "delete":
      return DEFAULT_DELETE_DIALOG_FIELDS;
  }
};

let confirmCallback: (() => void) | undefined = () => null;
export const useConfirmDialog = () => {
  const dialogOpen = useState(() => false);
  const dontShowAgain = useState(() => false);
  const dialogFields = useState<ConfirmDialogPayload>();

  const openDialog = (
    callback?: () => void,
    fields?: Partial<ConfirmDialogPayload>,
    type: DialogType = "confirm"
  ) => {
    const defaultFields = getDefault(type);
    dialogFields.value = { ...defaultFields, ...fields };
    dialogOpen.value = true;
    if (import.meta.client) confirmCallback = callback;
  };

  const _confirm = () => {
    if (confirmCallback) confirmCallback();
    dialogOpen.value = false;
  };

  const _cancel = () => {
    dialogOpen.value = false;
  };

  return {
    dialogOpen,
    openDialog,
    _confirm,
    _cancel,
    dontShowAgain,
    dialogFields,
  };
};

<template>
  <NuxtImg
    class="hidden dark:block!"
    :src="image.dark"
    :format="format"
    fit="contain"
    v-bind="props"
  />
  <NuxtImg
    class="dark:hidden"
    :src="image.light"
    :format="format"
    fit="contain"
    v-bind="props"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { HTMLAttributes } from "vue";

const props = defineProps<{
  variant: keyof typeof logoVariants;
  width?: string | number;
  class?: HTMLAttributes["class"];
  style?: HTMLAttributes["style"];
}>();

const logoVariants = {
  "ncb-square": {
    light: "/images/brand/ncb-square.svg",
    dark: "/images/brand/ncb-square-dark.svg",
  },
  "ncb-solac": {
    dark: "/images/brand/ncb-solac-dark.svg",
    light: "/images/brand/ncb-solac.svg",
  },
  "ncb-tech": {
    light: "/images/brand/ncb-tech.svg",
    dark: "/images/brand/ncb-tech-dark.svg",
  },
  "haz-h": {
    light: "/images/brand/haz_h.svg",
    dark: "/images/brand/haz_h_dark.svg",
  },
  "wsc-square": {
    light: "/images/brand/wsc-square.png",
    dark: "/images/brand/wsc-square-dark.png",
  },
  wsc: {
    light: "/images/brand/wsc.png",
    dark: "/images/brand/wsc-dark.png",
  },
};

const image = computed(() => {
  return logoVariants[props.variant];
});

const format = computed(() => {
  return image.value.light.endsWith(".png") ? "webp" : undefined;
});
</script>

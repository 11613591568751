<template>
  <q-dialog v-model="dialogOpen">
    <Card>
      <CardHeader icon="mdi:alert-box-outline">
        {{ dialogFields?.title || "Are you sure?" }}
      </CardHeader>
      <slot>
        <CardSection class="mb-2 whitespace">
          {{ dialogFields?.body || undefined }}
        </CardSection>
      </slot>
      <Separator />
      <CardActions>
        <q-checkbox
          v-if="dialogFields?.showDontShowAgain"
          v-model="dontShowAgain"
          label="Don't show again"
        />
        <Space />
        <Button
          flat
          :label="dialogFields?.cancelButton || 'Cancel'"
          @click.prevent="_cancel()"
        />
        <Button
          :variant="(dialogFields?.primaryButtonColour as any) ?? undefined"
          data-cy="dialogConfirm"
          :label="dialogFields?.confirmButton || 'general.confirm'"
          @click.prevent="_confirm()"
        />
      </CardActions>
    </Card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useConfirmDialog } from "~/composables/useConfirmDialog";
import { CardHeader } from "#components";

const { dialogOpen, _confirm, _cancel, dontShowAgain, dialogFields } =
  useConfirmDialog();
</script>

import { createError } from "#imports";
import { JSONPath } from "jsonpath-plus";
import { Probability } from "~/server/utils/issues";
import {
  groupProbability,
  type AnomalySummary,
} from "~/src/models/Case/Anomaly.model";
import {
  bookingPathMatchesField,
  type BookingViewModel,
} from "~/src/models/Case/Booking.viewmodel";
import {
  parseCtuAndMaybeCargoIndexFromField,
  parseSailingAndMaybeStageIndexFromField,
  type Path,
} from "~/utils/pathHelpers";
import { getParametersFromQueryString } from "~/utils/tokenPathFinder";

import type { DgPredictionSummary } from "~/src/models/Case/DgPrediction.model";
import type {
  HitViewModel,
  MatchViewModel,
  ScreenResultViewModel,
} from "~/src/models/Case/Screen.model";

function hitComparator(hit1: HitViewModel, hit2: HitViewModel): number {
  const libraryComparison = compareValues(hit1.library.id, hit2.library.id);
  if (libraryComparison !== 0) return libraryComparison;

  const ruleComparison = compareValues(
    hit1.rule.condition,
    hit2.rule.condition
  );

  if (ruleComparison !== 0) return ruleComparison;

  return compareValues(
    JSON.stringify(hit1.metadata),
    JSON.stringify(hit2.metadata)
  );
}

function compareValues<T>(a: T, b: T): number {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

const MatchKeys: (keyof MatchViewModel & keyof MatchViewModel)[] = [
  "keyword",
  "field",
  "text",
  "position",
  "length",
];

function matchComparator(
  match1: MatchViewModel | MatchViewModel,
  match2: MatchViewModel | MatchViewModel
): number {
  for (const key of MatchKeys) {
    const comparison = compareValues(match1[key], match2[key]);

    if (comparison !== 0) {
      return comparison;
    }
  }

  return 0;
}

export const hitListsAreEqual = (
  hitList1: HitViewModel[],
  hitList2: HitViewModel[]
) => {
  if (hitList1.length !== hitList2.length) {
    return false;
  }

  const sortedList1 = hitList1
    .map((hit) => {
      return { ...hit, matches: [...hit.matches].sort(matchComparator) };
    })
    .sort(hitComparator);

  const sortedList2 = hitList2
    .map((hit) => {
      return { ...hit, matches: [...hit.matches].sort(matchComparator) };
    })
    .sort(hitComparator);

  return JSON.stringify(sortedList1) === JSON.stringify(sortedList2);
};

export enum EntityType {
  CTU = "cargo-transport-units",
  Cargo = "cargo",
  CargoDetail = "cargo-detail",

  Party = "parties",
  Sailing = "sailings",
  Booking = "booking",
}

export const entityTypeDisplayNameMap: Record<EntityType, string> = {
  [EntityType.CTU]: "CTU",
  [EntityType.Cargo]: "Cargo",
  [EntityType.CargoDetail]: "",

  [EntityType.Party]: "Party",
  [EntityType.Sailing]: "Sailing",
  [EntityType.Booking]: "Booking",
};

export const entityTypeIconMap: Record<EntityType, string> = {
  [EntityType.CTU]: "mdi:package-variant",
  [EntityType.Cargo]: "mdi:cube",
  [EntityType.CargoDetail]: "",

  [EntityType.Party]: "mdi:account",
  [EntityType.Sailing]: "mdi:map-marker",
  [EntityType.Booking]: "mdi:book-open-blank-variant",
};

export const getEntityTypeFromField = (field: string): EntityType => {
  // order is important
  // if they have .cargo, .cargo-transport-units, .parties, .sailings etc...
  // chances are they are that thing

  if (field.includes("." + EntityType.Cargo)) return EntityType.Cargo;
  if (field.includes("." + EntityType.CTU)) return EntityType.CTU;
  if (field.includes("." + EntityType.Party)) return EntityType.Party;
  if (field.includes("." + EntityType.Sailing)) return EntityType.Sailing;

  console.error("NO MATCH FOUND FOR ENTITY TYPE", field, "RETURNING BOOKING");

  return EntityType.Booking;
};

const IGNORE = new Set(["text"]);
const CtuKeys = new Set([
  "ctu",
  "cargo-transport-unit",
  "cargo-transport-units",
]);
const CargoKeys = new Set(["cargo"]);
const PartyKeys = new Set(["parties"]);
const SailingKeys = new Set(["sailings"]);

export type IdOrIndex = number | string;
type InfoMap = {
  [EntityType.CTU]?: IdOrIndex | null;
  [EntityType.Cargo]?: IdOrIndex | null;
  [EntityType.CargoDetail]?: IdOrIndex[] | null;
  [EntityType.Party]?: IdOrIndex | null;
  [EntityType.Sailing]?: IdOrIndex | null;
  [EntityType.Booking]?: IdOrIndex | null;
};

const PATH_QUERY_PREFIX = "?(@.";

export function parseIdOrIndex(val: string) {
  if (val.startsWith(PATH_QUERY_PREFIX)) {
    // get it out of the thing here
    const { propertyValue } = getParametersFromQueryString(val);

    return propertyValue;
  }
  const num = Number(val);
  if (isNaN(num)) throw createError({ message: "failed to parse index" });
  return num;
}

const INFO_CACHE = new Map<string, InfoMap>();
export const getFriendlyLocationInfoFromField = (
  path: string | Path
): InfoMap => {
  const pathStr = typeof path === "string" ? path : path.build();
  const infoMap = INFO_CACHE.get(pathStr);
  if (infoMap) return infoMap;

  const parts =
    typeof path === "string" ? JSONPath.toPathArray(path) : path.path;

  const result: InfoMap = {};

  let lastEntity: EntityType | null = null;

  // skip first element as it's always $
  for (let i = 1; i < parts.length; i++) {
    const val = parts[i];
    if (IGNORE.has(val)) continue;

    if (lastEntity) {
      if (val.startsWith(PATH_QUERY_PREFIX)) {
        // get it out of the thing here
        const { propertyValue } = getParametersFromQueryString(val);

        result[lastEntity] = propertyValue;
      } else if (!isNaN(Number(val))) {
        result[lastEntity] = Number(val);
      } else if (lastEntity === EntityType.Party) {
        result[lastEntity] = val;
      } else {
        result[lastEntity] = null;
      }

      if (lastEntity === EntityType.Cargo && i !== parts.length - 1) {
        result[EntityType.CargoDetail] = parts.slice(i + 1);
      }

      lastEntity = null;
    } else if (
      i === parts.length - 1 &&
      Object.values(result).every((id) => id === null)
    ) {
      result[EntityType.Booking] = val;
    }

    if (CtuKeys.has(val)) {
      lastEntity = EntityType.CTU;
      result[EntityType.CTU] = null;
    } else if (CargoKeys.has(val)) {
      lastEntity = EntityType.Cargo;
      result[EntityType.Cargo] = null;
    } else if (PartyKeys.has(val)) {
      lastEntity = EntityType.Party;
      result[EntityType.Party] = null;
    } else if (SailingKeys.has(val)) {
      lastEntity = EntityType.Sailing;
      result[EntityType.Sailing] = null;
    }
  }

  INFO_CACHE.set(pathStr, result);

  return result;
};

export type HitPath = "containers" | "parties" | "sailings" | "booking";
export function parseHitPath(str: string): HitPath | null {
  const matches = str.matchAll(
    /cases\/[^/]*\/hits\/(containers|parties|sailings|booking)/gm
  );
  for (const match of matches) {
    return match[1] as HitPath;
  }
  return null;
}

export function parsePathFromMatch(field: string) {
  const info = getFriendlyLocationInfoFromField(field);
  if (info[EntityType.CTU] !== null) {
    return "containers";
  } else if (info[EntityType.Booking] !== null) {
    return "booking";
  } else if (info[EntityType.Sailing] !== null) {
    return "sailings";
  } else if (info[EntityType.Party] !== null) {
    return "parties";
  }
  return "booking";
}

export function pathHasDg(
  path: string | Path,
  summary: DgPredictionSummary | null
): boolean {
  if (!summary) return false;

  const builtPath = typeof path === "string" ? path : path.build();
  if (builtPath === "$.cargo-transport-units")
    return summary.cargoTransportUnits.some((ctu) => ctu.prediction);
  if (builtPath === "$") return summary.booking.prediction;

  const res = parseCtuAndMaybeCargoIndexFromField(builtPath);
  if (res === null) return false;

  return indexHasDg(summary, res[0], res[1]);
}

export function indexHasDg(
  summary: DgPredictionSummary | null,
  ctuIndex: number,
  cargoIndex: number | null
): boolean {
  if (summary === null || !summary.prediction) return false;

  const ctu = summary.cargoTransportUnits.find((ctu) => ctu.index === ctuIndex);
  if (ctu === undefined) return false;
  if (cargoIndex === null) return ctu.prediction;

  const cargo = ctu.cargo.find((cargo) => cargo.index === cargoIndex);
  return cargo !== undefined && cargo.prediction;
}

function hasProbability(
  summary: AnomalySummary,
  key: "origin" | "destination",
  minimumProbability: Probability
): boolean {
  // 🤢
  for (const ctu of summary.cargoTransportUnits) {
    for (const cargo of ctu.cargo) {
      for (const cargoRes of cargo.results) {
        for (const res of cargoRes.results) {
          if (res[key] === "None") continue;
          const prob = groupProbability(res.description);
          if (prob >= minimumProbability) return true;
        }
      }
    }
  }
  return false;
}

const minimumProbability = Probability.Possible;
export function pathHasAnomaly(
  path: string | Path,
  summary: AnomalySummary | null,
  booking: BookingViewModel
): boolean {
  if (!summary) return false;

  const builtPath = typeof path === "string" ? path : path.build();
  if (builtPath === "$.cargo-transport-units")
    return summary.cargoTransportUnits.length > 0;
  if (builtPath.startsWith("$.sailings")) {
    if (builtPath === "$.sailings") {
      return summary.sailings.length > 0;
    }
    const index = parseSailingAndMaybeStageIndexFromField(builtPath);
    if (!index) return false;
    const [ctuIndex, cargoIndex] = index;

    const ctuStart = ctuIndex === 0;
    const ctuEnd = ctuIndex === booking.sailings.length - 1;
    const cargoStart = cargoIndex === 0;
    const cargoNull = cargoIndex === null;
    const cargoEnd = () =>
      cargoIndex === booking.sailings.at(-1)!.stages.length - 1;

    if (
      !(ctuStart && (cargoNull || cargoStart)) &&
      !(ctuEnd && (cargoNull || cargoEnd()))
    )
      return false;

    return hasProbability(
      summary,
      ctuIndex === 0 ? "origin" : "destination",
      minimumProbability
    );
  }

  const res = parseCtuAndMaybeCargoIndexFromField(builtPath);
  if (res === null) return false;
  return indexHasAnomaly(res[0], res[1], summary);
}

export function indexHasAnomaly(
  ctuIndex: number,
  cargoIndex: number | null,
  summary: AnomalySummary | null
): boolean {
  if (!summary) return false;
  const ctu = summary.cargoTransportUnits.find((ctu) => ctu.index === ctuIndex);
  if (!ctu) return false;
  if (cargoIndex === null) return true;
  const cargo = ctu.cargo.find((cargo) => cargo.index === cargoIndex);
  if (!cargo) return false;
  return cargo.results.length > 0;
}

export const getFirstMatchForPath = (hits: HitViewModel[], path: string) => {
  for (let i = 0; i < hits.length; ++i) {
    const hit = hits[i];
    for (let j = 0; j < hit.matches.length; ++j) {
      const match = hit.matches[j];
      if (bookingPathMatchesField(path, match.field)) {
        return match;
      }
    }
  }
  return null;
};

export const getCurrentMatchInHitByPath = (hit: HitViewModel, path: string) => {
  const index = hit.matches.findIndex((x) =>
    bookingPathMatchesField(path, x.field)
  );
  return index === -1 ? null : index;
};

export function goToHit(
  previous: boolean,
  hitIndex: number,
  currentScreening: ScreenResultViewModel,
  wrapAround = false
) {
  const newIndex = hitIndex + (previous ? -1 : 1);
  const firstLast = previous
    ? newIndex === 0
    : newIndex === currentScreening.hits.length;
  if (firstLast && wrapAround) {
    return [previous ? 0 : currentScreening.hits.length - 1, 0] as const;
  }

  if (newIndex >= currentScreening.hits.length || newIndex < 0) return null;
  return [newIndex, 0] as const;
}

export function goToMatch(
  previous: boolean,
  hit: HitViewModel,
  hitIndex: number,
  currentMatchIndex: number,
  currentScreening: ScreenResultViewModel
) {
  const incr = previous ? -1 : 1;
  if (
    previous
      ? currentMatchIndex === 0
      : currentMatchIndex + 1 === hit.matches.length
  ) {
    if (
      previous ? hitIndex === 0 : hitIndex + 1 === currentScreening.hits.length
    ) {
      return null;
    }

    const nextHitIndex = hitIndex + incr;
    const nextHit = currentScreening.hits[nextHitIndex];
    return [nextHitIndex, previous ? nextHit.matches.length - 1 : 0] as const;
  }

  const nextMatchIndex = currentMatchIndex + incr;
  if (nextMatchIndex >= hit.matches.length || nextMatchIndex < 0) return null;
  return [hitIndex, nextMatchIndex] as const;
}

export function hitString(hit: number, match: number) {
  return `${hit + 1},${match + 1}`;
}

export function parseHitString(str: string): [number, number] {
  const arr = str.split(",");
  if (arr.length === 0) throw "shit mate";
  const hitIndex = parseInt(arr[0]) - 1;
  if (arr.length === 1) return [hitIndex, 0] as const;
  const matchIndex = parseInt(arr[1]) - 1;
  return [hitIndex, matchIndex] as const;
}

export function hitId(match: MatchViewModel) {
  return `hit:${match.field}:${match.position}`;
}

export function getMatchInfo(field: string): [IdOrIndex | null, string] {
  const info = getFriendlyLocationInfoFromField(field);
  if (info[EntityType.CTU] !== null) {
    const detail = info[EntityType.CargoDetail];
    const displayField = detail?.[0] ?? null;
    return [displayField, "containers"];
  } else if (info[EntityType.Booking] !== null) {
    const displayField = String(info[EntityType.Booking]);
    return [displayField, "booking"];
  } else if (info[EntityType.Sailing] !== null) {
    let displayField =
      Array.from(field.matchAll(/stages\[\d+\]\.(.+?)(\.|$)/gi)).at(0)?.[1] ??
      null;
    if (!displayField) {
      displayField =
        Array.from(field.matchAll(/stages\[(\d)+\]/gi)).at(0)?.[1] ?? null;
      if (displayField) displayField = "stage " + (parseInt(displayField) + 1);
    }
    return [displayField, "sailings"];
  } else if (info[EntityType.Party] !== null) {
    const displayField = info[EntityType.Party] ?? null;
    return [displayField, "parties"];
  }
  return [null, "booking"];
}

export type MatchType = "rule" | "dg" | "anomaly";
const matchPriority = ["rule", "dg", "anomaly"] as const;

type Fn = (arg: any[] | null, _: HitPath | null) => boolean;
type CanSwitch = Record<MatchType, Fn>;
const canSwitchLookup = {
  rule(arg, _) {
    return arg !== null && arg.length !== 0;
  },
  dg(arr, hitUrlPath) {
    return (
      arr != null &&
      arr.length > 0 &&
      (hitUrlPath === null ||
        hitUrlPath === "containers" ||
        hitUrlPath === "booking")
    );
  },
  anomaly(arr, hitUrlPath) {
    return (
      arr != null &&
      arr.length > 0 &&
      (hitUrlPath === null ||
        hitUrlPath === "containers" ||
        hitUrlPath === "sailings")
    );
  },
} as const satisfies CanSwitch;

type Hits = Record<MatchType, any[] | null>;
const canSwitch = (
  match: MatchType,
  hitUrlPath: HitPath | null,
  hits: Hits
): boolean => canSwitchLookup[match](hits[match], hitUrlPath);

export function getMatchTypeOrNull(
  matchType: MatchType,
  hitUrlPath: HitPath | null,
  hits: {
    rule: any[];
    dg: any[] | null;
    anomaly: any[] | null;
  }
): MatchType | null {
  if (canSwitch(matchType, hitUrlPath, hits)) return matchType;
  for (const otherMatch of matchPriority) {
    if (otherMatch === matchType) continue;
    if (canSwitch(otherMatch, hitUrlPath, hits)) return otherMatch;
  }
  return null;
}

export function getMatchType(
  matchType: MatchType,
  hitUrlPath: HitPath | null,
  hits: {
    rule: any[];
    dg: any[] | null;
    anomaly: any[] | null;
  }
): MatchType {
  return getMatchTypeOrNull(matchType, hitUrlPath, hits) ?? matchType;
}

export function getDefaultQuery(match: MatchType | null) {
  if (match === "dg") return { match: "dg" };
  if (match === "anomaly") return { match: "anomaly" };
  return { match: "rule", hit: hitString(0, 0) };
}

export function getMatchQuery(
  query: any,
  matchType: MatchType,
  hitUrlPath: HitPath | null,
  hits: {
    rule: any[];
    dg: any[] | null;
    anomaly: any[] | null;
  }
): any {
  const toMatch = getMatchType(matchType, hitUrlPath, hits);
  if (toMatch !== matchType) return getDefaultQuery(toMatch);
  return query;
}

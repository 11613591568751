import { unref, type MaybeRef } from "vue";
import { useOurNuxtApp } from "~/utils/nuxt";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { useQueryClient } from "@tanstack/vue-query";

import type { UsersGetSchema } from "~/server/api/cm/users/index.get";
import type { RailLinkPutBody } from "~/server/api/auth/user/rail.put";

const endpoints = {
  list: "/api/cm/users",
  get: parseUriTemplate("/api/cm/users/{id}"),
  railLinks: { get: "/api/auth/user/rail", set: "/api/auth/user/rail" },
} as const;

export const useUserService = () => {
  const { $api } = useOurNuxtApp();
  const queryClient = useQueryClient();

  // Case List
  const listUsers = (
    signal?: AbortSignal,
    search?: string,
    page = 1,
    pageSize = 50
  ) => {
    return $api(endpoints.list, {
      method: "get",
      params: {
        pageSize,
        search,
        page,
      } satisfies UsersGetSchema,
      signal: signal,
    });
  };

  type Options<T extends boolean = true> = {
    suspense?: T;
    createNuxtError?: boolean;
  };
  function useListUsersQuery<T extends boolean = true>(options?: Options<T>) {
    return createQuery(
      [QUERY_KEYS.Users.list],
      ({ signal }) => listUsers(signal),
      options
    );
  }

  const getUser = async (id: string, signal?: AbortSignal) => {
    const result = await $api(endpoints.get.expand({ id }), { signal });
    return result || null;
  };
  const useGetUserQuery = <T extends boolean = true>(
    id: MaybeRef<string | null>,
    createNuxtError = true,
    suspense: T
  ) => {
    return createQuery(
      [QUERY_KEYS.Users.get, id],
      ({ signal }) => {
        const _id = unref(id);
        if (_id) return getUser(_id, signal);
        return Promise.resolve(null);
      },
      {
        createNuxtError,
        suspense,
      }
    );
  };

  const getRailLinks = async (signal?: AbortSignal) => {
    const result = await $api(endpoints.railLinks.get, { signal });
    return result ?? null;
  };
  const useRailLinksQuery = (options: MyQueryOptions) => {
    return createQuery(
      [QUERY_KEYS.Users.Rail.get],
      ({ signal }) => getRailLinks(signal),
      options
    );
  };

  const getLoggedInUser = (signal?: AbortSignal) =>
    $api("/api/auth/user", { signal });
  const useLoggedInUserQuery = (options?: MyQueryOptions) => {
    return createQuery(
      [QUERY_KEYS.Users.current],
      ({ signal }) => getLoggedInUser(signal),
      options
    );
  };

  const getCurrentToken = (signal?: AbortSignal) =>
    $api("/api/auth/jwt", { signal });
  const useCurrentTokenQuery = (options?: MyQueryOptions) => {
    return createQuery(
      [QUERY_KEYS.Users.currentToken],
      ({ signal }) => getLoggedInUser(signal),
      options
    );
  };

  const setRailLinks = async (railLinks: RailLinkPutBody) => {
    return $api(endpoints.railLinks.set, { method: "PUT", body: railLinks });
  };
  const useRailLinksMutation = () => {
    return createMutation(setRailLinks, {
      showSuccess: false,
      showError: false,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Users.Rail.get],
        });
      },
    });
  };

  return {
    listUsers,
    useListUsersQuery,
    useGetUserQuery,
    useRailLinksQuery,
    getRailLinks,
    useRailLinksMutation,
    getLoggedInUser,
    useLoggedInUserQuery,
    getCurrentToken,
    useCurrentTokenQuery,
  };
};

<template>
  <div class="column flex items-center justify-center" style="height: 90vh">
    <Card class="column" style="width: 50em">
      <CardHeader icon="mdi:alert-circle">
        {{ $t("error.encountered") }}
      </CardHeader>

      <CardSection class="column gap-2">
        <h4 v-if="error?.statusCode">
          {{ $t("general.code") }}: {{ error?.statusCode }}
        </h4>

        <h4 style="text-transform: unset">{{ error?.message }}</h4>
      </CardSection>

      

      <CardActions>
        <Space />

        <Button
          label="Go Back"
          icon="mdi:step-backward"
          flat
          @click.prevent="goBack()"
        />

        <Button
          label="general.home"
          icon="mdi:home"
          @click.prevent="handleGoHome()"
        />
      </CardActions>
    </Card>

    <div class="flex justify-center gap-4 mt-2">
      <Logo width="115px" variant="wsc" />
      <Logo width="160px" style="margin-bottom: -1px" variant="ncb-solac" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app";
import { clearError } from "#imports";
import useGoBack from "~/composables/useGoBack";

const goBack = useGoBack();

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError,
});

// eslint-disable-next-line no-console
console.error(props.error);

const handleGoHome = () => clearError({ redirect: "/" });
</script>

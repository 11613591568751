<template>
  <NuxtIcon :class="[variants({ variant, size }), props.class]" :name>
    <slot />
  </NuxtIcon>
</template>

<script setup lang="ts">
import { cva, type VariantProps } from "class-variance-authority";

import type { HTMLAttributes } from "vue";

type IconVariants = VariantProps<typeof variants>;
export type Props = {
  variant?: IconVariants["variant"];
  size?: IconVariants["size"];
  name: string;
  class?: HTMLAttributes["class"];
};

const props = withDefaults(defineProps<Props>(), {
  variant: "default",
  size: "default",
  class: undefined,
});

const variants = cva("", {
  variants: {
    variant: {
      default: "text-neutral-800 dark:text-neutral-100",
      disabled: "text-weak",
      negative: "text-negative",
    },
    size: {
      xs: "text-[1rem]",
      sm: "text-lg",
      md: "text-2xl",
      lg: "text-4xl",
      default: "text-[1.3em]",
    },
  },
});
</script>

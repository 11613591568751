import { useUserStore } from "~/stores/userStore";
import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

import type { RouteLocationNormalized } from "vue-router";

const unauthorisedPages = ["/", "/inspections/response"];

const isOpenRoute = (route: RouteLocationNormalized) => {
  return (
    route.path.startsWith("/api") || unauthorisedPages.includes(route.path)
  );
};

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const store = useUserStore();
  const auth = await store.init();

  if (!auth.isLoggedIn && !isOpenRoute(to)) {
    const url = `/api/auth/login?redirect=${encodeURIComponent(to.fullPath)}`;
    console.info("Redirecting to login then", to.fullPath);
    return navigateTo(url, {
      redirectCode: 303,
      external: true,
    });
  }
});

<template>
  <button
    v-bind="props"
    :class="[
      buttonVariants({ variant, size, dense, disabled, square, round }),
      props.class,
    ]"
    :disabled="disabled || undefined"
  >
    <Spinner v-if="loading" :size="size ?? undefined" />
    <slot v-else name="icon">
      <Icon
        v-if="icon"
        :variant="disabled ? 'disabled' : 'default'"
        :class="[iconClass, variant === 'primary' ? 'text-white!' : undefined]"
        :disabled="disabled || undefined"
        :size="size ?? undefined"
        :name="icon"
      />
    </slot>
    <slot>
      {{ label ? $t(label) : "" }}
    </slot>
  </button>
</template>

<script setup lang="ts">
import { buttonVariants, defaultProps, type SharedProps } from "./shared";

export type Props = SharedProps;

const props = withDefaults(defineProps<Props>(), defaultProps);
</script>


// @ts-nocheck
import locale_en_46json_2efd90d2 from "#nuxt-i18n/2efd90d2";
import locale_en_45us_46json_5e4c95ac from "#nuxt-i18n/5e4c95ac";

export const localeCodes =  [
  "en",
  "en-us"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_2efd90d2",
      load: () => Promise.resolve(locale_en_46json_2efd90d2),
      cache: true
    }
  ],
  "en-us": [
    {
      key: "locale_en_46json_2efd90d2",
      load: () => Promise.resolve(locale_en_46json_2efd90d2),
      cache: true
    },
    {
      key: "locale_en_45us_46json_5e4c95ac",
      load: () => Promise.resolve(locale_en_45us_46json_5e4c95ac),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      name: "British English",
      iso: "gb",
      isCatchallLocale: true,
      files: [
        "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en.json"
      ]
    },
    {
      code: "en-us",
      files: [
        "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en.json",
        "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en-us.json"
      ],
      name: "American English",
      iso: "us"
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "en",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    name: "British English",
    iso: "gb",
    isCatchallLocale: true,
    files: [
      {
        path: "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en-us",
    files: [
      {
        path: "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en.json",
        cache: undefined
      },
      {
        path: "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/i18n/locales/en-us.json",
        cache: undefined
      }
    ],
    name: "American English",
    iso: "us"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/
<template>
  <div
    ref="cardEl"
    class="rounded-md overflow-hidden"
    :class="{
      'border border-weak': bordered,
      'bg-main': !flat,
      'shadow-bottom': !flat,
    }"
    v-bind="$attrs"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

withDefaults(
  defineProps<{
    flat?: boolean | undefined;
    bordered?: boolean | undefined;
  }>(),
  { flat: false, bordered: true }
);

const cardEl = ref<HTMLDivElement | null>(null);
defineExpose({ el: cardEl.value ?? null });
</script>

<template>
  <svg
    class="text-neutral-800 dark:text-neutral-100 spinner"
    :width="dim"
    :height="dim"
    viewBox="25 25 50 50"
  >
    <circle
      class="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke="currentColor"
      stroke-width="5"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{ size?: "xs" | "sm" | "md" | "lg" }>();

const dim = computed(() => {
  if (props.size === undefined) return "1em";
  if (props.size === "xs") return "18px";
  if (props.size === "sm") return "24px";
  if (props.size === "md") return "32px";
  if (props.size === "lg") return "38px";
  return "1em";
});
</script>

<style scoped lang="scss">
@keyframes spinner {
  0% {
    transform: rotate3d(0, 0, 1, 0deg) /* rtl:ignore */;
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg) /* rtl:ignore */;
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg) /* rtl:ignore */;
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg) /* rtl:ignore */;
  }
  100% {
    transform: rotate3d(0, 0, 1, 359deg) /* rtl:ignore */;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.spinner {
  animation: spinner 2s linear infinite;
  transform-origin: center center;
}
.path {
  stroke-dasharray:
    1,
    200 /* rtl:ignore */;
  stroke-dashoffset: 0 /* rtl:ignore */;
  animation: dash 1.5s ease-in-out infinite;
}
</style>
